export const sortnum = ((col,order,list,setList,setOrder) => {
    if(order === "ASC"){
    const sorted = [...list].sort((a,b) =>
    parseInt(a[col]) > parseInt(b[col]) ? 1 : -1)
    setList(sorted);
    setOrder("DESC");
    }
    if(order === "DESC"){
        const sorted = [...list].sort((a,b) =>
        parseInt(a[col]) < parseInt(b[col]) ? 1 : -1)
        setList(sorted);
        setOrder("ASC");
        }
        })

 export const sortit = ((col,order,list,setList,setOrder,setWork) => {

    console.log(list)
     if(order === "ASC"){
            const sorted = [...list].sort((a,b) =>
            a[col] > b[col] ? 1 : -1)
            setList(sorted);
            setOrder("DESC");
            }
            if(order === "DESC"){
                const sorted = [...list].sort((a,b) =>
                a[col] < b[col] ? 1 : -1)
                setList(sorted);
                setOrder("ASC");
                }
              
                })

                export const sortdate = ((col,order,list,setList,setOrder) => {
                    if(order === "ASC"){
                           const sorted = [...list].sort((a,b) =>
                           Date.parse(a[col]) > Date.parse(b[col]) ? 1 : -1)
                           setList(sorted);
                           setOrder("DESC");
                           }
                           if(order === "DESC"){
                               const sorted = [...list].sort((a,b) =>
                               Date.parse(a[col]) < Date.parse(b[col]) ? 1 : -1)
                               setList(sorted);
                               setOrder("ASC");
                               }
                               })

        
                               export const sortnumbers = ((col,order,list) => {
                    
                            if(list){
                                if(order === "ASC"){
                                const sorted = [...list].sort((a,b) =>
                                parseInt(a[col]) > parseInt(b[col]) ? 1 : -1)
                                return sorted;
                               
                                }
                                if(order === "DESC"){
                                    const sorted = [...list].sort((a,b) =>
                                    parseInt(a[col]) < parseInt(b[col]) ? -1 : 1)
                                    return sorted;
                              
                                    }
                                }
                                    })


                                    export const sortshippingcustomer = ((col,order,list,setList,setOrder) => {
                                        console.log(list)
                                         if(order === "ASC"){
                                                const sorted = [...list].sort((a,b) =>
                                                a.customerid.name > b.customerid.name ? 1 : -1)
                                                setList(sorted);
                                                setOrder("DESC");
                                                }
                                                if(order === "DESC"){
                                                    const sorted = [...list].sort((a,b) =>
                                                        a.customerid.name < b.customerid.name ? 1 : -1)
                                                    setList(sorted);
                                                    setOrder("ASC");
                                                    }
                                                    })
                                                    export const sortshippingdescription = ((col,order,list,setList,setOrder) => {
                                                        console.log(list)
                                                         if(order === "ASC"){
                                                                const sorted = [...list].sort((a,b) =>
                                                                a.job.jobname > b.job.jobname ? 1 : -1)
                                                                setList(sorted);
                                                                setOrder("DESC");
                                                                }
                                                                if(order === "DESC"){
                                                                    const sorted = [...list].sort((a,b) =>
                                                                        a.job.jobname < b.job.jobname ? 1 : -1)
                                                                    setList(sorted);
                                                                    setOrder("ASC");
                                                                    }
                                                                    })