import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Datepick, Flex, Flexed, Input, MainWrap, TinySquare,Label, Button, Selectbox } from '../../utilities/Shared'
import Modal from '../../modals/Modal'
import PrepressTicket from './jobticket/PrepressTicket'
import EstVact from './jobticket/EstVact'
import NewEst from './jobticket/NewEst'
import PressTicket from './jobticket/PressTicket'
import { CSRRepsByEmpid, SalesRepsByEmpid } from '../../utilities/Reps'
import Jobticket from '../../Printing/Jobticket'
import { methods, presses, pullcutlist } from '../../utilities/Lists'
import { colorchange } from '../../utilities/Convertions'
import Moment from 'react-moment'
import BinderyTicket from './jobticket/BinderyTicket'
import MailTicket from './jobticket/MailTicket'
import Stitchingticket from './jobticket/StitchingTicket'
import UserContext from '../../Context/UserContext'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize';
import Select from '../../utilities/Select'
import { TbPlayerTrackPrevFilled,TbPlayerTrackNextFilled, TbUmbrella, TbUmbrellaFilled  } from "react-icons/tb";
import Ospticket from './jobticket/Ospticket'
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io'
import { FaFilePdf } from "react-icons/fa6";
import PressContext from '../../Context/PressContext'
import  RiCloseLargeFill  from "react-icons/ri";
import { TfiClose } from 'react-icons/tfi'
import { MdOutlineClose } from 'react-icons/md'
import StitchingContext from '../../Context/StitchingContext'
import { IoRemoveCircleOutline } from 'react-icons/io5'
import Collapse from '../../utilities/Collapse'
import { JobticketProvider } from '../../Context/JobticketContext'
import ChatContext from '../../Context/ChatContext'
import NotificationContext from '../../Context/Notifications'

export const Textarea = styled(TextareaAutosize)`
font-size:1.4rem;
width:100%;
border:none;
padding:10px 5px 30px 5px;
overflow:hidden;
resize:none;
flex:1;
:focus{
    border:2px solid var(--plsGreen);  
  }

`;


const JobTicket = () => {

  const[reset, setReset] = useState(false)
const param = useParams()

const[operations, setOperations] = useState([])
const[dateentered, setDateentered] = useState()
const[duedate, setDuedate] = useState(new Date())
const[imposition, setImposition] = useState([])
const[forms, setForms] = useState([])
const[editprepressticket, setEditprepressticket] = useState(false)
const[edittime, setEdittime] = useState(false)
const [prepressticket, setPrepressticket] = useState(false)
const [pressticket, setPressticket] = useState(false)
const [binderyticket, setBinderyticket] = useState(false)
const [mailticket, setMailticket] = useState(false)
const [stitchingticket, setStitchingticket] = useState(false)
const [setjobnumber, setSetjobnumber] = useState()
const[po, setPo] = useState()
const[s2checked, setS2checked] = useState(false)
const [formchange, setFormchange] = useState({})
const [paperlist, setPaperslist] = useState([])
const [nod, setNod] = useState(true)
const [nods, setNods] = useState(false)
const[inks, setInks] = useState()
const[allocatedate, setAllocatedate] = useState(null)
const[OSP, setOSP] = useState([])
const[ospPo, setOspPo] = useState([])
const[ospticket, setOspticket] = useState(false)
const[contacts, setContacts] = useState([])
const[shipping, setShipping] = useState([])
const[locations, setLocations] = useState([])
const[statustext, setStatustext] = useState("On Incoming")
const[partialtext, setPartialtext] = useState("")
const [quoteinfo, setQuoteinfo] = useState()
const[customerinfo, setCustomerinfo] = useState([])
const [sentitems, setSentitems] = useState([])
const nav = useNavigate()

const {userInfo}  = useContext(UserContext)
const {presslist} = useContext(PressContext)


const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*' 
  }
};

let r = {
  job:param.job.replace(/\D/g, "")
}





useEffect(() => {
  
const getjobinfo = async() => {
  let r = {'jobnum':param.job.replace(/\D/g, "")}
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
   //await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
  .then(res => { 
      setCustomerinfo(res.data[0]);
      getsentitems(res.data[0]?.job)
      const getimpo = async() => {
          let q ={
              qoute:customerinfo?.quote
          }
    
          await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getjobimposition.php`,q,config)
          .then(res => {
         
              setQuoteinfo(res.data);
          
              getops()})
          .catch(err => console.log(err))
      }
      getimpo()
  })
  .catch(err=>console.log(err))
}
   
  const getcust = async() => {
    await axios.get(`https://highgroundapi.herokuapp.com/customers/hg/name/${customerinfo.customer}`)
    .then(res => {
   
setLocations(res.data[0]?.address)
setContacts(res.data[0]?.contacts)
    })
    .catch(err => console.log(err))
  }

  


  getcust()
  getjobinfo()
},[customerinfo.id])

 
useEffect(() => {
  settext()
},[operations])

   
const getsentitems = async(jobnum) => {

  let r ={
    jobnum:jobnum
  }

 // await axios.get(`https://highgroundapi.herokuapp.com/customers/hg/item/${customerid}`)
  await axios.post(`https://highgroundapi.herokuapp.com/ship/getitems`,r)
 .then(res => {

  setSentitems(res.data)
})
 .catch(err => console.log(err))
}


useEffect(() => {
 
  const getcurrent = async() => {
       
    let r = {'jobnum':param.job.replace(/\D/g, "")}
   await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
    //await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
   .then(res => { 
    setCustomerinfo(res.data[0]);
    setDateentered(new Date(res.data[0].dateentered));
    let result = new Date(res.data[0].duedate);
    setDuedate(result)
    setAllocatedate(new Date(res.data[0].allocatedate) > 0 ? new Date(res.data[0].allocatedate) : "")
    setForms(res.data[0].forms)
    setImposition(setimp(res.data[0].imposition))
    getops()
    getpos()
    getoutsideservices(res.data[0])
  
  })
   .catch(err => console.log(err))
  
   }
   const p = async() => {
    //await axios.get('https://highgroundapi.herokuapp.com/paper/all')
    await axios.get('https://highgroundapi.herokuapp.com/paper/all')
    .then(res => {setPaperslist(res.data);})
    .catch(err => console.log(err)) 
}
p()
   getcurrent()   
   setSetjobnumber(param.job)

   getship()
},[reset,nod])





//

const getship = async() => {
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getshipping.php`,r,config)
  .then(res => {
    setShipping(res.data); 
   
  })
  .catch(err => console.log(err))
}



const setimp = (imp) => {
  let ii = []
  let a
  let b
  let c
  if(imp[0].tottopress > 0){
    return imp
    
  }else{

    imp?.map(item => {
      a = parseInt(item?.needed) + parseInt(item?.additionalmr)  
    b = parseInt(item.mroverride) || parseInt(item.mr)
    c = (a + b) - parseInt(item.mroverride)
  
  r = {
     ...item,
     tottopress: a + b,
     totmr: b,
      totoff: c
   }
   ii.push(r)
  }
  
  )
  return ii
  }


}


const getpos = async() => {
  let u = {
    job:param.job.replace(/\D/g, "")
  }

 await axios.post(`https://highgroundapi.herokuapp.com/po/get/job/po`,u)
 .then(res => {

  let uu = []
  let ii = []
  let r  = res.data.map(item => {
   return item.items.map(it => {

    if(it.stockid > ""){
let u ={
  ...it,ponum:item.ponum,vendor:item.vendor.company.name,dateordered:item.createdAt,duedate:item.dueDate
}

       uu.push(u)
    }else{
      let hg ={
        ...it,ponum:item.ponum,vendor:item.vendor.company.name,dateordered:item.createdAt,duedate:item.dueDate
      }
      
             ii.push(hg)  
    }
      
    })

  })

  setPo(uu)
  setOspPo(ii)
})

 .catch(err => console.log(err))
 }



 const getoutsideservices = async(item) => {
  let h
  const r ={
    quote:item?.quote
  }



  if(item.osp?.length <= 0 || !item.osp){
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoutsideservices.php`,r,config)
    .then(res => {
      if(res.data.length > 0){
        let r = {
          id:item?._id,
          field:'osp',
           value:res.data
         }
        yy(r)
      }
   /*
     const setnewosp = () => {
      
     }
   
     setnewosp()*/
    })
    .catch(err => console.log(err))
  }
 }



 const yy = async(r) => {
 // await axios.post(`http://localhost:5003/jobs/update`,r)
 await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
.then(res => {setReset(!reset)})
.catch(err => console.log(err))

 }



const getops = async() => {
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,r,config)
 .then(res => {setOperations(res.data);})
 .catch(err => console.log(err))
 }

 
const changeDate = (date) => {

  let h = {
    table:"jobs",
    field:"duedate",
    value:date,
    id:customerinfo.id
  }
  const setolddate = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatedynamic.php`,h,config)
    .then(res => {})
    .catch(err => console.log(err))
        
  }
 //yesterday.setDate(today.getDate() - 1); 
  let r = {
    id:customerinfo._id,
     value:new Date(date),
     field:'duedate'
   }
   
   const getopss = async() => {
    console.log(r)
    await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
    //await axios.post(`http://localhost:5003/jobs/update`,r)
    .then(res => {  
      console.log(res)
    setDuedate(r.value);
    setolddate()

    })
    .catch(err => console.log(err))
    }
  getopss()

//   let r = {
//     id:customerinfo._id,
//      value:date,
//      field:'duedate'
//    }
   
//    console.log(r)
//    const getopss = async() => {
//     // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
//      await axios.post(`http://localhost:5003/jobs/update`,r)
//     .then(res => {  console.log(res.data) })   //setDuedate(r.value);setolddate()
//     .catch(err => console.log(err))
//     }
   
//     const setolddate = async() => {
//       let l = {
//         table:"jobs",
//         field:"duedate",
//         value:date,
//         id:customerinfo.id
//       } 
//       await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatedynamic.php`,l,config)
//       .then(res => console.log())
//       .catch(err => console.log(err))
          
//     }

// getopss()
}

const changeentDate = (date) => {
  let h = {
    table:"jobs",
    field:"dateentered",
    value:date,
    id:customerinfo.id
  }
  const setolddate = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatedynamic.php`,h,config)
    .then(res => {setReset(!reset)})
    .catch(err => console.log(err))
        
  }
  let r = {
    id:customerinfo._id,
     value:new Date(date),
     field:'dateentered'
   }
   
   const getopss = async() => {
     await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
    .then(res => {  
    setDateentered(r.value);
    setolddate()

    })
    .catch(err => console.log(err))
    }
  getopss()
}

const changeAllocatedate = (date) => {
  let r = {
    id:customerinfo._id,
     value:new Date(date),
     field:'allocatedate'
   }
   

   const getops = async() => {
     await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
    .then(res => {  setReset(!reset)})
    .catch(err => console.log(err))
    }
 getops()
}
   
  
const settext = () => {
  const sorted = [...operations].sort((a,b) =>
  parseInt(a.id) < parseInt(b.id) ? 1 : -1)



  const getshipinfo = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getjobcustomerbyjob.php`,r,config)
    .then(res => {
      let u

     if(res.data[0].shipstatus === "Shipped" || res.data[0].shipstatus === "Mailed"){
      u= true
      setStatustext(`${res.data[0].shipstatus} - ${res.data[0]?.shipdate}`)
     }else{
      u= false
      sorted.length > 0 && setStatustext(`${sorted[0]?.name} - ${new Date(sorted[0]?.changedon).toLocaleDateString()}`)
     }

     let sdate = 
     shipping?.length > 0 && u === false ? setPartialtext(`Partial Shipment Sent - ${new Date(shipping[0]?.dateshipped).toLocaleDateString()}`) : setPartialtext("")

     

    })
    .catch(err => console.log(err))
  }
  getshipinfo()
}


const settopress = (imposition) => {

  let ii = []
  if(!imposition[0].topress){
  alert()//
    let a
    let b  
    let c
    let r
    customerinfo.imposition?.map(item => {
     a = parseInt(item?.needed) + parseInt(item?.additionalmr)  
   b = parseInt(item.mroverride) || parseInt(item.mr)
   c = (a + b) - parseInt(item.mroverride)

 r = {
  id:param.job.replace(/\D/g, ""),
  value:{
    ...item,
    tottopress: a + b,
    totmr: b,
     totoff: c
  }
  
}
ii.push(r) 


  })

const tt = async(r) => {
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/topress`,ii)
    .then(res => {})
    .catch(err => console.log(err))
}
tt(r)
  }else{

  }
  
}





const newinfo = (e,item) => {

    let r = {
      id:customerinfo?._id,
      field:item,
       value:customerinfo[item]
     }
    
     const yyr = async(r) => {
      await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
      // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
      .then(res => {
       let tr ={
        job:customerinfo.job.toString(),
        item:item.toLowerCase(),
        value:customerinfo[item]
       }
    setold(tr)
    
      
      })
      .catch(err => console.log(err))
     }
   yyr(r)
    }


  const setold = async(tr) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*' 
      }
    };
  
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updateJobDescriptions.php`,tr,config)
   .then(res => {setReset(!reset)})
   .catch(err => console.log(err))
   }







  const setcheckbox = (e,item) => {
   
  let check = e.target?.checked 

  let r = {
    id:customerinfo?._id,
    field:item,
     value:check
   }
   
  yy(r)
    }


   
    const getstatus = (partofid,cat,itemtype,digital,partof) => {
   
      let dept = 3  
if(digital){
dept = 2
}
      let y
     
      if(itemtype === "multi"){
        switch(cat){
          case "prepress":
            y =  operations?.filter(filt => parseInt(filt.partofid) === parseInt(partofid) && filt.dept < 3)
            break;
            case "press":
              y =  operations?.filter(filt =>  parseInt(filt.partofid) ===  parseInt(partofid) &&  parseInt(filt.dept) === dept)
              break;
              case "bindery":
                y =  operations?.filter(filt =>  parseInt(filt.partofid) ===  parseInt(partofid) &&  parseInt(filt.dept) === 4 && parseInt(filt.process) < 20)
                break;
             
           
            }
           return(y[0]?.name)
      }
       
      if(itemtype === "single"){
        switch(cat){
          case "prepress":
            y =  operations?.filter(filt => parseInt(filt.partof) === parseInt(partof) && parseInt(filt.dept) < 2)
           
            break;
            case "press":
              y =  operations?.filter(filt =>  parseInt(filt.item) ===  parseInt(partof) &&  parseInt(filt.dept) === dept)
              break;
              case "bindery":
                y =  operations?.filter(filt =>  parseInt(filt.partof) ===  parseInt(partof) &&  parseInt(filt.dept) === 4 && parseInt(filt.process) < 20)
                break;
               
            }
           return(y[0]?.name)
      }
      }


      const getstichstatus = (stat) => {
        let y
        switch(stat){
          case "stitching":
            y =  operations?.filter(filt => parseInt(filt.dept) === 4 && parseInt(filt.process) >= 20)
            break;
            case "mailing":
              y =  operations?.filter(filt => parseInt(filt.dept) === 6)
              break;
        }
        return(y[0]?.name)
      }
    
  

      const newfink = async(e,item,field) => {
   
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/fink`,r)
 // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/forms`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))
      
      }

      const newbink = async(e,item,field) => {
    
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/bink`,r)
 // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/forms`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))
      
      }

      const newfaq = async(e,item,field) => {
     
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/faq`,r)
 // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/faq`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))
      
      }


      const newbaq = async(e,item,field) => {
       
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/baq`,r)
 // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/baq`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))
      
      }

      const newwidth = async(e,item,field) => {
     
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/width`,r)
 // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/width`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))
      
      }

      const newlength = async(e,item,field) => {
  
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/length`,r)
 // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/length`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))
      
      }

      const newversions = async(e,item,field) => {
    
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/versions`,r)
 //await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/versions`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))
      
      }

      const newpages = async(e,item,field) => {
       
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 //await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/pages`,r)
 await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/pages`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))
      
      }


      const newup = async(e,item,field) => {
    
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 //await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/up`,r)
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/up`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))  
      }

      const newpress = async(e,item,field) => {
   
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 //await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/press`,r)
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/press`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))  
     }

      const newmethod = async(e,item,field) => {
      
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 //await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/method`,r)
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/method`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))  
      }

      const newpaper = async(e,item,field) => {
       
 const r = {
  field,
  value:e.target.value,
  id:item.formid
 }

 //await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/paper`,r)
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/paper`,r)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))  
      }



      const setitem = async(e,item) => {
   
    let newname = e.target.value.split(' - ')[0];

 let yt = []
       let g = paperlist.filter(filt => filt.name === newname)
      let h =  imposition.findIndex((imp) => imp.id === item.id);

if(g.length > 0){
 

 let nforms = forms

 nforms.map((form,index) => {
  if(form.item === item.id){
forms[index].paper = g[0].name
forms[index].stockid = g[0].stockid
  }
 })

 let nimpo = imposition

 nimpo.map((form,index) => {
   if(form.id === item.id){
 imposition[index].paper = g[0].name
 imposition[index].paperid = g[0].stockid
   }
  })

const loadforms = async()=> {
  let r = {
    id:customerinfo._id,
    field:'forms',
     value:forms
   }

   await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
   // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
   .then(res => {})
   .catch(err => console.log(err))
  
}

const loadimpo = async()=> {


  let r = {
    id:customerinfo._id,
    field:'imposition',
     value:imposition
   }

   await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
   // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
   .then(res => {setReset(!reset)})
   .catch(err => console.log(err))
  
}

loadforms()
loadimpo()
}else{
  
}
            }


const setnewplength = async(e,item,field) => {
let nimpo = imposition

nimpo.map((form,index) => {
  if(form.id === item.id){
imposition[index][field] = e.target.value
let r = {
  id:item.id,
   value:e.target.value
 }
 const g = async() => {
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/plength`,r)
  // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
  .then(res => {setReset(!reset)})
  .catch(err => console.log(err))
 }
g()
  }
 })
}

 const setnewpwidth = async(e,item,field) => {
  let nimpo = imposition

  nimpo.map((form,index) => {
    if(form.id === item.id){
  imposition[index][field] = e.target.value
  let r = {
    id:item.id,
     value:e.target.value
   }
   const g = async() => {
    await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/pwidth`,r)
    // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
    .then(res => {setReset(!reset)})
    .catch(err => console.log(err))
   }
  g()
    }
   })
}

const setnewpout = async(e,item,field) => {
  let nimpo = imposition

  nimpo.map((form,index) => {
    if(form.id === item.id){
  imposition[index][field] = e.target.value
  let r = {
    id:item.id,
     value:e.target.value
   }

   const g = async() => {
    //await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/pout`,r)
    await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/pout`,r)
    .then(res => {setReset(!reset)})
    .catch(err => console.log(err))
   }
 g()
    }
   })
}


const changequantity = (e,id,field,index) => {

let r
let imp = imposition[index]


  let newArr = forms.findIndex((form) => form.formid === id);
  let a
  let b
  let d

switch(field){
case "to":
   a = forms[newArr].topress.to = parseInt(e.target.value )
  d = a - forms[newArr].topress.mr
  break; 
  case "mr":
   b = forms[newArr].topress.mr = parseInt(e.target.value) 
   d = forms[newArr].topress.to - b
  break; 
  case "totto":
   b = forms[newArr].topress.totto = parseInt(e.target.value) 
   d = a - forms[newArr].topress.totmr
  break; 
}
forms[newArr].topress.off = d  
  //setForms(forms)
if(parseInt(imp?.numforms) > 1){
  let tu = 0
  let tumr = 0
  let tuoff = 0
  let u = forms.filter((form) => form?.id === imp[index]?.id);
 u.map(ii => {
  tu +=(parseInt(ii.topress.to) + parseInt(ii.topress.mr))
  tumr +=(parseInt(ii.topress.mr))

 })
 tuoff = tu - tumr
imp.tottopress = tu
imp.totmr = tumr
imp.totoff = tuoff

}else{
  imp.tottopress = forms[newArr].topress.to
imp.totmr = forms[newArr].topress.mr
imp.totoff = forms[newArr].topress.off

}

 const newimp = async() => {
  let t = {
    id:customerinfo._id,
    field:'imposition',
    value:imposition
   }
await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,t)
.then(res => {
setImposition(res.data.imposition)
})
.catch(err => console.log(err))
 }

 const newformz = async() => {
  let t = {
    id:customerinfo._id,
    field:'forms',
    value:forms
   }
await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,t)
.then(res => {
setForms(res.data.forms)
setReset(!reset)
})
.catch(err => console.log(err))
 }
 newformz()
 newimp()

}

const sendnewquantity = async(id) => {

  let y = forms.findIndex((form) => form.formid === id);

 const r = {
    id:id,
    value:forms[y].topress
  }


await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/quan`,r)
  // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
  .then(res => {setReset(!reset)})//setForms(res.data.forms)})
 .catch(err => console.log(err))
 
}




const setnewtopress = async(e,index,field) => {


  let y = imposition[index]
  let a
  let b
  let d
switch(field){
case "tottopress":
   a = y.tottopress = parseInt(e.target.value )
    d = a - y.totmr
  break; 
  case "totmr":
   b = y.totmr = parseInt(e.target.value) 
   d = y.tottopress - b
  break; 
}
y.totoff = parseInt(d)
}

const changeimp = (e,index,field) => {


 imposition[index][field] = e.target.value

const newimp = async() => {
  let t = {
    id:customerinfo._id,
    field:'imposition',
    value:imposition
   }
await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,t)
.then(res => {
setImposition(res.data.imposition)
})
.catch(err => console.log(err))
 }
 newimp()
}

const sendnewtots = async(index) => {
  const r = {
    id:customerinfo._id,
    value:imposition
  }
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/topress`,r)
  // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
.then(res => {setImposition(res.data.imposition)})
 .catch(err => console.log(err))
}


const sendnewforms = async(index) => {
  const r = {
    id:customerinfo._id,
    value:forms
  }
  //await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/forms`,r)
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/update/forms`,r)
.then(res => {setForms(res.data.forms)})
 .catch(err => console.log(err))
}



const reroll = async(id,job) => {
  let u = {
    id:id
  }
  await axios.post(`https://highgroundapi.herokuapp.com/jobs/delete`,u)
  // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
  .then(res => { nav(`/jobticket/${param.job}`)})
 .catch(err => console.log(err))
}



const changedrop = (e,field) => {

  let r = {
    id:customerinfo._id,
     value:e.target.value,
     field:field
   }
   

   const getops = async() => {
     await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
    .then(res => {  
      let tr ={
        job:customerinfo.job.toString(),
        item:r.field,
        value:r.value
       }
    setold(tr)

    })
    .catch(err => console.log(err))
    }
  getops()
}
     



const next = (job) => {


  let u = parseInt(job) + 1
  nav(`/jobticket/${u}`)
}

const prev = (job) => {
  let u = parseInt(job) - 1
  nav(`/jobticket/${u}`)
  }


const strip = (value) => {
  let g = value.replace(/(<([^>]+)>)/ig,"")
  return g
}


const changeosp = () => {

}

const deleteosp = (index) => {
let uu = customerinfo.osp

  uu.splice(index, 1)

  let r = {
    id:customerinfo?._id,
    field:'osp',
     value:uu
   }
  yy(r)
}


const getpounds = (item) => {



  let poundset = item.parentsheetl * item.parentsheetw * item.paperweight  * 2;
//	$poundset = $c['sheetl'] * $c['sheetw'] * $paper['weight'] * 2;
let pounset2 = Math.ceil(poundset / 950)
//	$poundset = ceil($poundset / $paper['divide']);
let pounset3 = item.tottopress / item.parentout
//	$mradded = $topress/$c['parentout'];
let pounset4 = Math.ceil((pounset2 * pounset3) / 1000)
//	 $sneed = number_format(ceil(($poundset * $mradded) / 1000));
item.pounds = pounset4
return pounset4
}


const viewpopdf = (pdf) => {
  let url = 'https://highgroundsoftware.com/dash/dashboard/production/pouploads/'+encodeURIComponent(pdf);
  PopupCenter(url, 'PO', 900, 500);
  }

  function PopupCenter(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth :window.screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    var systemZoom = width / window.screen.availWidth;
var left = (width - w) / 2 / systemZoom + dualScreenLeft
var top = (height - h) / 2 / systemZoom + dualScreenTop
    var newWindow = window.open(url, title, 'scrollbars=no, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window?.focus) newWindow?.focus();
}


const changebinderyitem = (e,item,index,field) => {
 imposition[index][field] = e.target.value
const setimp = async() => {
  let h = {
    id:customerinfo._id,
    value:imposition
  }

await axios.post('https://highgroundapi.herokuapp.com/jobs/update/topress',h)
.then(res => {setImposition(res.data.imposition)})
.catch(err => console.log(err))
}
setimp()
}

const changeformitem = (e,item,index,field) => {

  forms[index][field] = e.target.value

  let r = {
    id:customerinfo?._id,
    field:'forms',
     value:forms
   }

   const yyr = async(r) => {
   await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
    // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
    .then(res => {setReset(!reset)})
    .catch(err => console.log(err))
   }
 yyr(r)
 }






  return (
   
<MainWrap >
  <JobticketProvider>
<Modal show={editprepressticket} header={`Pre Press Info`} onCancel={() => {setEditprepressticket(false)}} ><PrepressTicket onCancel={() => {setEditprepressticket(false)}}  reset={reset} setReset={setReset} jobinfo={customerinfo} /></Modal>

<Modal headertextAlign="center" contentbgcolor="white" show={edittime} header={`Estimate Versus Actual`} onCancel={() => {setEdittime(false)}} ><EstVact onCancel={() => {setEdittime(false)}}  job={param.job} impo={imposition} forms={forms} operations={operations} reset={reset} setReset={setReset}   jobinfo={customerinfo}/></Modal>

<Modal show={prepressticket} header={<Flexed><Flex fontSize="calc(1.4rem + .2vw)" flex="3">Pre-Press Times For Job {param.job}</Flex><Flex>On <TinySquare bgcolor="var(--on)" /></Flex><Flex>Off <TinySquare bgcolor="var(--off)" /></Flex><Flex>Incomplete <TinySquare bgcolor="var(--incomplete)" /></Flex><Flex>AA Changes <TinySquare bgcolor="var(--aa)" /></Flex><Flex>PE Changes <TinySquare bgcolor="var(--pe)" /></Flex></Flexed>} onCancel={() => {setPrepressticket(false)}} ><PrepressTicket onCancel={() => {setPrepressticket(false)}}  jobinfo={customerinfo} reset={reset} setReset={setReset} job={param.job}  forms={forms} /></Modal>

<Modal show={pressticket} header={<Flexed><Flex fontSize="calc(1.4rem + .2vw)" flex="3">Press Times For Job {param.job}</Flex><Flex>On <TinySquare bgcolor="var(--on)" /></Flex><Flex>Off <TinySquare bgcolor="var(--off)" /></Flex><Flex>Incomplete <TinySquare bgcolor="var(--incomplete)" /></Flex><Flex>AA Changes <TinySquare bgcolor="var(--aa)" /></Flex><Flex>PE Changes <TinySquare bgcolor="var(--pe)" /></Flex></Flexed>} onCancel={() => {setPressticket(false)}} ><PressTicket onCancel={() => {setPressticket(false)}}  reset={reset} setReset={setReset}  forms={forms} ops={operations} job={param.job} /></Modal>

<Modal show={binderyticket} header={<Flexed><Flex fontSize="calc(1.4rem + .2vw)" flex="3">Bindery Times For Job {param.job}</Flex><Flex>On <TinySquare bgcolor="var(--on)" /></Flex><Flex>Off <TinySquare bgcolor="var(--off)" /></Flex><Flex>Incomplete <TinySquare bgcolor="var(--incomplete)" /></Flex><Flex>AA Changes <TinySquare bgcolor="var(--aa)" /></Flex><Flex>PE Changes <TinySquare bgcolor="var(--pe)" /></Flex></Flexed>} onCancel={() => {setBinderyticket(false)}} ><BinderyTicket onCancel={() => {setBinderyticket(false)}}  reset={reset} setReset={setReset}  forms={forms} ops={operations} job={param.job} /></Modal>

<Modal show={ospticket} header={<Flexed><Flex fontSize="calc(1.4rem + .2vw)" flex="3">Add Outsideservices</Flex></Flexed>} onCancel={() => {setOspticket(false)}} ><Ospticket onCancel={() => {setOspticket(false)}}  reset={reset} setReset={setReset} osp={customerinfo.osp} job={customerinfo._id}/></Modal>

<Modal show={stitchingticket} header={<Flexed><Flex fontSize="calc(1.4rem + .2vw)" flex="3">Stitching Times</Flex><Flex textAlign="right"><MdOutlineClose style={{fontWeight:"bold",fontSize:"calc(1.5rem + .2vw)",color:"red"}} /></Flex></Flexed>} onCancel={() => {setStitchingticket(false)}} ><Stitchingticket onCancel={() => {setStitchingticket(false)}}  reset={reset} setReset={setReset}  forms={forms} jobinfo={customerinfo} job={param.job}/></Modal>

<Modal show={mailticket} header={<Flexed><Flex fontSize="calc(1.4rem + .2vw)" flex="3">Mailing Times</Flex></Flexed>} onCancel={() => {setMailticket(false)}} ><MailTicket onCancel={() => {setMailticket(false)}}  reset={reset} setReset={setReset}  forms={forms} jobinfo={customerinfo} job={param.job}/></Modal>


<div style={{background:"#EAE6E1",padding:"0px 5px 5px 5px"}}>

        <Flexed padding="7px 0px"  bgColor="#0E425A" color="white" margin="20px 0px 0px 0px">
        <TbPlayerTrackPrevFilled style={{color:"white",position:"absolute",right:"110px",fontSize:"calc(1.5rem + .2vw)",zIndex:"100"}} onClick={e => prev(param.job)} />
        <TbPlayerTrackNextFilled   style={{color:"white",position:"absolute",right:"25px",fontSize:"calc(1.5rem + .2vw)",zIndex:"100"}} onClick={e => next(param.job)}/>
            <Flex textAlign="center" fontSize="var(--headerLarge)">Job Ticket</Flex>
           
        </Flexed>
        <Flexed position="relative">
        {userInfo.empId === "1537" || userInfo.empId === "1312" || userInfo.empId === "1648" ?
        <div style={{position:"absolute",bottom:"0px",left:"65%",display:"flex",justifyContent:"flex-start"}}><div style={{fontSize:"calc(1.2rem + .2vw)",display:"flex",alignItems:"center"}}>Ship Aprroval</div><div style={{display:"flex",alignItems:"center",paddingLeft:"10px"}}><Input width="20px" type="checkbox"  onClick={e => setcheckbox(e,'cod')}    checked={customerinfo.cod === 1 ? true :false}/></div></div>
        : null}
    <Flex flex="2"><Input type="text" height="50px" fontWeight="bold" fontSize="18pt" value={customerinfo?.customer?.replace(/&amp;/g, "&")} disabled /><br /><Label>Customer</Label></Flex>
    <Flex flex=".5"><br /><Input fontWeight="bold" type="text" value={customerinfo?.customerid} disabled/><br /><Label>Cust#</Label></Flex>
    <Flex flex=".5"><br /><Datepick fontWeight="bold" selected={dateentered}  onChange={(date) => changeentDate(date)} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/> <br /><Label>Entered</Label></Flex>
    <Flex flex=".5"  ><br /><Datepick fontWeight="bold" selected={duedate}  onChange={(date) => changeDate(date)} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/> <br /><Label>Due</Label>

    </Flex>
    <Flex flex="0" ><form onSubmit={e => nav(`/jobticket/${setjobnumber}`)}><Input onChange={e => setSetjobnumber(e.target.value)} fontWeight="bold" type="text" textAlign="center" width="200px" height="65px" value={setjobnumber} fontSize="32pt"/></form><br /><Label>Job</Label>
    <button style={{marginLeft:"25px",padding:"5px 20px"}} onClick={e => reroll(customerinfo._id)} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}>Reroll</button>
     </Flex>
</Flexed>

<Flexed  margin="20px 0px 0px 0px">
    <Flex  flex="1.5"><Input fontWeight="bold" type="text" value={customerinfo?.jobname} onChange={e => setCustomerinfo({...customerinfo,jobname:e.target.value})} onBlur={e => newinfo(e,'jobname')} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/><br /><Label>Job Name</Label></Flex>
    <Flex><Input fontWeight="bold" type="text" value={customerinfo?.jobtype} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/><br /><Label>Job Type</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex  flex=".5"><Input fontWeight="bold" type="text" value={customerinfo?.quote} disabled/><br /><Label>Quote#</Label></Flex>
    <Flex  flex=".5"><Input fontWeight="bold" type="text" value={customerinfo?.prevjob} onChange={e => setCustomerinfo({...customerinfo,prevjob:e.target.value})} onBlur={e => newinfo(e,'prevjob')} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/><br /><Label>Prev Job#</Label></Flex>
    <Flex  flex=".5"><Input fontWeight="bold" type="text" value={customerinfo?.custpo} onChange={e => setCustomerinfo({...customerinfo,custpo:e.target.value})} onBlur={e => newinfo(e,'custpo')} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/><br /><Label>PO#</Label></Flex>
</Flexed>
<Flexed  margin="20px 0px 20px 0px">
    <Flex><SalesRepsByEmpid value={customerinfo?.salesrep} style={{fontWeight:"bold"}} onChange={e => changedrop(e,'salesrep')} disabled={userInfo.departments?.some(cat => cat.label === "Administration" || parseInt(userInfo.empId) === 1710) ? false : true}/><br /><Label>Sales Rep</Label></Flex>

    <Flex><CSRRepsByEmpid  value={customerinfo?.csr} style={{fontWeight:"bold"}} onChange={e => changedrop(e,'csr')} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/><Label>Csr</Label></Flex>

    <Flex>
    <Input  type="text"  list='contacts' defaultValue={customerinfo.contact} onChange={e => changedrop(e,'contact')} required />
   <datalist id='contacts'>
{
    contacts?.map((cust,index) => {
     return   <option key={index} data-item={cust.name} value={cust.name}  /> 
    })
}
   </datalist>
      
      <Label>Contact</Label></Flex>
      
    <Flex flex="1.5" textAlign="center" >
      <Button bgcolor="#79BB43" hcursor="normal" padding="8px 25px" fontSize="calc(1.1rem + .3vw)" type="button" borderRadius="5px" border="0px solid Black" color="white">{statustext}</Button><br /><div style={{fontSize:"calc(1rem + .2vw)",fontWeight:"bold",paddingTop:"7px"}}>{partialtext}</div>
    </Flex>
    <Flex textAlign="right">
     {/*<a href={`https://highgroundsoftware.com/dash/dashboard/?des=779&job=${setjobnumber}`}>
      <Button bgcolor="buttonface" padding="8px 25px" fontSize="calc(1.1rem + .3vw)" border="1px solid Black" >White Sheet</Button>
</a>*/}
<Button bgcolor="buttonface" onClick={e => nav(`/whitesheet/${setjobnumber}`)} padding="8px 25px" fontSize="calc(1.1rem + .3vw)" border="1px solid Black" >White Sheet</Button>
      </Flex>
    
</Flexed>
<hr />
<Flexed>
    <Flex padding="20px 0px" fontSize="1.9rem" fontWeight="bold">Quantity Ordered:&nbsp;{customerinfo?.quantity}</Flex>
    <Flex>{userInfo.auth?.includes('600') && <Button style={{transition:"1s easeIn",visibility:operations.length > 0 ? "normal" : "none"}} onClick={e => {setEdittime(true)}}>EVA</Button>}</Flex>
    <Flex textAlign="right" alignItems="flex-end" display="flex" ><Jobticket  po={po} osp={OSP} osppo={ospPo} imposition={imposition} customerinfo={customerinfo} presses={presses} methods={methods} duedate={duedate} forms={forms} title="Print Ticket" /></Flex>
</Flexed>
<Flexed gap="30px">
    <Flex fontSize="1.3rem" border="1px solid rgb(0,0,0,.4)">Description 1<br /><Textarea value={customerinfo?.pnotes?.replace(/&amp;/g, "&")} minRows="8" onBlur={e => newinfo(e,'pnotes')} onChange={e => setCustomerinfo({...customerinfo,pnotes:strip(e.target.value)})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/></Flex>
    <Flex fontSize="1.3rem" border="1px solid rgb(0,0,0,.4)">Description 2<br /><Textarea value={customerinfo?.desc2?.replace(/&amp;/g, "&")} minRows="8" onBlur={e => newinfo(e,'desc2')} onChange={e => setCustomerinfo({...customerinfo,desc2:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/></Flex>
    <Flex fontSize="1.3rem" border="1px solid rgb(0,0,0,.4)">Description 3<br /><Textarea value={customerinfo?.desc3?.replace(/&amp;/g, "&")} minRows="8" onBlur={e => newinfo(e,'desc3')} onChange={e => setCustomerinfo({...customerinfo,desc3:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true}/></Flex>
</Flexed>
<Flexed padding="7px 0px"  bgColor="#0E425A" color="white" margin="20px 0px 0px 0px">
    <Flex flex="2" fontSize="1.3rem" fontWeight="bold">Pre-Press</Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>S2&nbsp;&nbsp;<Input width="20px" type="checkbox" onClick={e => setcheckbox(e,'s2')}   disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true} checked={customerinfo.s2 === "true" ? true :false}/></Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>S44&nbsp;&nbsp;<Input  width="20px"  type="checkbox" onClick={e => setcheckbox(e,'s44')} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true} checked={customerinfo.s44 === "true" ? true :false}/></Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>1st Off&nbsp;&nbsp;<Input  width="20px"  type="checkbox" onClick={e => setcheckbox(e,'oneoff')} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true} checked={customerinfo.oneoff === "true" ? true :false}/></Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>PDF&nbsp;&nbsp;<Input  width="20px"  type="checkbox" onClick={e => setcheckbox(e,'pdf')} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true} checked={customerinfo.pdf === "true" ? true :false}/></Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>Press Check&nbsp;&nbsp;<Input  width="20px"  type="checkbox" onClick={e => setcheckbox(e,'presscheck')} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") ? false : true} checked={customerinfo.presscheck === "true" ? true :false}/></Flex>
    <Flex flex=".3"></Flex>
    <Flex display="flex" justifyContent="flex-end"><Button color="black" hcolor="white" onClick={e => setPrepressticket(true)} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr") || userInfo.departments?.some(cat => cat.label === "Pre-Press")? false : true}>Punches</Button></Flex>
</Flexed>

<Flexed padding="3px">
<Flex fontSize="1.3rem" flex="2">Component</Flex>
<Flex flex=".4" fontSize="1.3rem">Pages </Flex>
<Flex flex=".4" fontSize="1.3rem">#Up</Flex>
<Flex flex=".4" fontSize="1.3rem">Forms</Flex>
<Flex flex=".4" fontSize="1.3rem">Ver's</Flex>
<Flex fontSize="1.3rem">Press</Flex>
<Flex flex=".8" fontSize="1.3rem">Sheet Size</Flex>
<Flex fontSize="1.3rem">Method</Flex>
<Flex fontSize="1.3rem">Status</Flex>
</Flexed>

{imposition?.map(imp => {
return forms?.filter(filt => parseInt(filt.item) === parseInt(imp.id)).map((newitem,index) => {
return <>
      <Flexed key={index} >
      <Flex flex="2" fontSize="calc(1rem + .2vw)" top="7px">{newitem.name}</Flex>
          <Flex flex=".4"><Input type="text" fontSize="1.1rem" value={newitem.pages} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Pre-Press")? false : true}/> </Flex>
          <Flex flex=".4"><Input type="text" fontSize="1.1rem" value={newitem.numup} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Pre-Press")? false : true}/></Flex>
          <Flex flex=".4"><Input type="text" fontSize="1.1rem" defaultValue={newitem.numforms} onBlur={e => changeformitem(e,newitem,index,'numforms')} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Pre-Press")? false : true}/></Flex>
          <Flex flex=".4"><Input type="text" fontSize="1.1rem" value={newitem.versions} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Pre-Press")? false : true}/></Flex>
          <Flex >
          <Selectbox disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Pre-Press")? false : true}>
        {
          presslist.map(item => {
            return <option value={item.id} selected={item.id == newitem.pressid ? true : false}>{item.pname}</option>
          })
        }
        </Selectbox>
            </Flex>
          <Flex flex=".4"><Input type="text" fontSize="1.1rem" value={newitem.length} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Pre-Press")? false : true}/></Flex>
          <Flex flex=".4"><Input type="text" fontSize="1.1rem" value={newitem.width} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Pre-Press")? false : true}/></Flex>
          <Flex >
          <Selectbox disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Pre-Press")? false : true}>
        {
          methods.map(item => {
      
            return <option value={item.id} selected={item.name == newitem.method ? true : false}>{item.name}</option>
          })
        }
        </Selectbox> 
            </Flex>
          <Flex fontSize="1.2rem" bgColor={colorchange(getstatus(newitem.partofid,'prepress',newitem.sett,newitem.digital,newitem.item))} 
         
       
          padding="5px" height="27px" top="3px">
      {getstatus(newitem.partofid,'prepress',newitem.sett,newitem.digital,newitem.item)}
          </Flex>
          </Flexed>


      </>
  

})}

)}
<Flexed style={{marginTop:"5px"}} border="1px solid rgb(0,0,0,.4)">
<Textarea  value={customerinfo?.prepressnotes?.replace(/&amp;/g, "&")} placeholder="Pre-Press Notes" minRows="1" onBlur={e => newinfo(e,'prepressnotes')} onChange={e => setCustomerinfo({...customerinfo,prepressnotes:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Pre-Press") || userInfo.departments?.some(cat => cat.label === "Csr")? false : true}/>
</Flexed>



{/*/////////////////////////////////////////////////////////PRESS///////////////////////////////////////////////////////////////////////////////////////////*/}

<Flexed padding="7px 0px"  bgColor="#0E425A"  margin="20px 0px 0px 0px">
    <Flex color="white" flex="3" fontSize="1.3rem" fontWeight="bold">Press</Flex>  <Flex display="flex" justifyContent="flex-end"><Button hcolor="white" onClick={e => setPressticket(true)} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Press") || userInfo.departments?.some(cat => cat.label === "Digital") ? false : true} >Punches</Button></Flex>
</Flexed>


{imposition.map((item,index) => {
return <>

<Flexed bgColor="rgb(14,66,90,.4)" padding="3px" alignItems="center" >
  <Flex fontSize="calc(1rem + .2vw)" fontWeight="bold" flex="7.5">{item.component} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{item.numforms} Forms&nbsp; - &nbsp;{item.versions} version &nbsp;&nbsp; {item.pages}Pg /  &nbsp;&nbsp;{item.numforms} - {item.pages / item.numforms} page(s) running {item.numup}up on a {item.length} X {item.width} {item.method}</Flex>
  {parseInt(item.numforms) > 1 && parseInt(item.press) !== 7  && parseInt(item.press) !== 8 && parseInt(item.press) !== 9 && parseInt(item.press) !== 10? <><Flex  fontSize="1.1rem">Total To Press: <Input  type="text" fontSize="1.1rem" value={parseInt(item.tottopress)} onChange={e => setnewtopress(e,index,'tottopress')} onBlur={e => sendnewtots(index)} disabled={true}/></Flex>
       <Flex  fontSize="1.1rem">Total M/R: <Input  type="text" fontSize="1.1rem" value={parseInt(item?.totmr)} onChange={e => setnewtopress(e,index,'totmr')} onBlur={e => sendnewtots(index)} disabled={true} /></Flex>
       <Flex  fontSize="1.1rem">Total Off Press: <Input  type="text" fontSize="1.1rem" value={item?.totoff } disabled={true}/></Flex></> : ""}
   </Flexed>
  {forms.filter(filt => filt.item == item.id).map((newitem) => {
   
return <>
<div className={item.numforms <= 1 ? "greenrow" : "normalrows"}>
   <Flexed  padding="3px" >
  <Flex top="21px" fontSize="calc(1rem + .2vw)" fontWeight="bold" flex="1" >{newitem.name}</Flex>
  <Flex  fontSize="1.1rem" flex=".5" >Ver's:<br /> <Input  type="text" fontSize="1.1rem" defaultValue={newitem.versions} onBlur={e => newversions(e,newitem,'versions')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
  <Flex  fontSize="1.1rem" flex=".5">Pages:<br /> <Input  type="text" fontSize="1.1rem" defaultValue={parseInt(newitem.pages)} onBlur={e => newpages(e,newitem,'pages')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
  <Flex  fontSize="1.1rem" flex=".5">Up:<br /> <Input  type="text" fontSize="1.1rem" defaultValue={newitem.numup} onBlur={e => newup(e,newitem,'numup')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>

    <Flex >
   Press:<br />
    <Selectbox onChange={e => newpress(e,newitem,'press')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}>
  {
    presslist.map(press => {
      return <option value={press.id} selected={parseInt(press.id) === parseInt(newitem.pressid) ? true : false}>{press.pname}</option>
    })
  }
  </Selectbox>
      </Flex>
      <Flex >Method:<br />
     <Selectbox  onChange={e => newmethod(e,newitem,'method')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}>
   {
     methods.map(method => {

       return <option value={method.name} selected={method.name == newitem.method ? true : false}>{method.name}</option>
     })
   }
   </Selectbox> 
   
       </Flex>








       <Flex  fontSize="1.1rem" flex=".5">To Press: <Input type="text" fontSize="1.1rem" defaultValue={Math.ceil(newitem.topress.to)}  onBlur={e => changequantity(e,newitem.formid,'to',index)} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>

       <Flex  fontSize="1.1rem" flex=".5">M/R: <Input type="text" fontSize="1.1rem" defaultValue={Math.ceil(newitem.topress.mr)}  onBlur={e => changequantity(e,newitem.formid,'mr',index)} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>









       <Flex  fontSize="1.1rem" flex=".5">Off Press: <Input type="text" fontSize="1.1rem" value={Math.ceil(newitem.topress.off)} disabled/></Flex>
       <Flex style={{zIndex:"100"}} fontSize="1.2rem" bgColor={colorchange(getstatus(newitem.partofid,'press',newitem.sett,newitem.digital,newitem.item))} 
         
       
          padding="5px" height="27px" top="15px">
      {getstatus(newitem.partofid,'press',newitem.sett,newitem.digital,newitem.item)}
          </Flex>

  </Flexed>
  <Flexed  padding="8px" borderBottom="1px solid grey">
 <Flex  flex="1" fontSize="1.1rem" >Paper:<br /> <span style={{fontSize:"1.3rem",position:"relative",top:"8px"}}>{newitem.paper} - {newitem.stockid}</span></Flex>
     <Flex flex=".6">
       Size:<br />
       <Flexed>
       <Flex ><Input type="text" fontSize="1.1rem" defaultValue={newitem.length} onBlur={e => newlength(e,newitem,'length')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
     <Flex ><Input type="text" fontSize="1.1rem" defaultValue={newitem.width} onBlur={e => newwidth(e,newitem,'width')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>  
       </Flexed>
      </Flex>
      <Flex flex=".5" fontSize="1.1rem" padding="0px 5px">Front: <Input type="text" fontSize="1.1rem" defaultValue={newitem?.fink} onBlur={e => newfink(e,newitem,'fink')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
      <Flex flex=".5" fontSize="1.1rem" padding="0px 5px">Front Coat: 
      
      <Selectbox defaultValue={newitem?.faq} onBlur={e => newfaq(e,newitem,'faq')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}>
      <option value="">None</option>
   <optgroup label="Flood">
		<option value="FGAQ">F Gloss AQ</option>
		<option value="FDAQ">F Dull AQ</option>
		<option value="FSTAQ">F Soft Touch AQ</option>
		<option value="FPAQ">F Pearl AQ</option>
		<option value="FSAQ">F Satin AQ</option>
		<option value="FGUV">F Gloss UV</option>
		<option value="FDUV">F Dull UV</option>
<option value="F CC">F Clear Coat</option>
   </optgroup>
    <optgroup label="Spot">
		<option value="SGAQ">S Gloss AQ</option>
		<option value="SDAQ">S Dull AQ</option>
		<option value="SSTAQ">S Soft Touch AQ</option>
		<option value="SPAQ">S Pearl AQ</option>
		<option value="SSAQ">S Satin AQ</option>
		<option value="SGUV">S Gloss UV</option>
		<option value="SDUV">S Dull UV</option>
<option value="S CC">S Clear Coat</option>
   </optgroup>
      </Selectbox>

      
      </Flex>
     <Flex flex=".5" fontSize="1.1rem" padding="0px 5px">Back: <Input type="text" fontSize="1.1rem" defaultValue={newitem?.bink} onBlur={e => newbink(e,newitem,'bink')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
     <Flex flex=".5" fontSize="1.1rem" padding="0px 5px">Back Coat: 
     <Selectbox defaultValue={newitem?.baq} onBlur={e => newbaq(e,newitem,'baq')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}>
      <option value="">None</option>
   <optgroup label="Flood">
		<option value="FGAQ">F Gloss AQ</option>
		<option value="FDAQ">F Dull AQ</option>
		<option value="FSTAQ">F Soft Touch AQ</option>
		<option value="FPAQ">F Pearl AQ</option>
		<option value="FSAQ">F Satin AQ</option>
		<option value="FGUV">F Gloss UV</option>
		<option value="FDUV">F Dull UV</option>
<option value="F CC">F Clear Coat</option>
   </optgroup>
    <optgroup label="Spot">
		<option value="SGAQ">S Gloss AQ</option>
		<option value="SDAQ">S Dull AQ</option>
		<option value="SSTAQ">S Soft Touch AQ</option>
		<option value="SPAQ">S Pearl AQ</option>
		<option value="SSAQ">S Satin AQ</option>
		<option value="SGUV">S Gloss UV</option>
		<option value="SDUV">S Dull UV</option>
<option value="S CC">S Clear Coat</option>
   </optgroup>
      </Selectbox>
     
     </Flex>
     </Flexed>
     <Flexed bgColor="rgb(14,66,90,.2)" padding="3px" borderBottom="1px solid grey" margin="0px 0px 15px 0px">
    
   <Flex flex="3"></Flex>
     
     </Flexed>
     </div>   
</>
}
  )}
  
</>
}
)}
<Flexed style={{marginTop:"-10px"}} border="1px solid rgb(0,0,0,.4)">
<Textarea value={customerinfo?.pressnotes?.replace(/&amp;/g, "&")} placeholder="Press Notes" minRows="1" rows="1"  onBlur={e => newinfo(e,'pressnotes')} onChange={e => setCustomerinfo({...customerinfo,pressnotes:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr")? false : true}/>
</Flexed>
{/*/////////////////////////////////////////////////////////STOCK///////////////////////////////////////////////////////////////////////////////////////////*/}

<Flexed padding="7px 0px"  bgColor="#0E425A"  margin="20px 0px 0px 0px">
    <Flex color="white" flex=".5" fontSize="1.3rem" fontWeight="bold">Stock</Flex> 
    <Flex >
 <Selectbox onChange={e => changedrop(e,'allocated')} value={customerinfo?.allocated} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}>
 <option value="">Select Stock Status</option>
 <option value="Allocated">Allocated</option>
 </Selectbox>
   </Flex>
   <Flex ><Datepick  selected={allocatedate}  onChange={(date) => changeAllocatedate(date)} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
   <Flex  >
    
 <Selectbox onChange={e => changedrop(e,'pullcut')} value={customerinfo?.pullcut} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}>
 <option value="">Select Pull / Cut Assigner</option>
 <option value="Robert H">Robert H</option>
 <option value="Pam B">Pam B</option>
 <option value="Duane H">Duane H</option>
 <option value="Kevin C">Kevin C</option>
 <option value="Laura N">Laura N</option>
 </Selectbox>
   </Flex>    
   <Flex flex="3"></Flex>
</Flexed>
<div style={{border:"1px solid rgb(0,0,0,.5)"}}>
<Flexed bgColor="#92A4AB" padding="4px 0px">
<Flex fontSize="1.2rem" fontWeight="bold" flex="2" >Component</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex="4">Stock</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex=".5">ID#</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex=".5">LBS</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">PO #</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Sheets</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex=".5">Out</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex="1.7">Size</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Supplier</Flex>
  
  <Flex fontSize="1.2rem" fontWeight="bold">Ordered</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Due</Flex>
</Flexed>
{imposition.map((item,index) => {
return <Flexed padding="4px 0px">
    <Flex fontSize="1.1rem" flex="2" top="8px" >{item.component}</Flex>
<Flex fontSize="1.2rem" flex="4">
<Input  type="text"  list='papers' defaultValue={item.paper} onChange={e => setitem(e,item)} required disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/>
   <datalist id='papers'>
{
    paperlist?.map((paper,index) => {
     return   <option key={index} data-item={paper.stockid} value={`${paper.name} - ${paper.stockid}`}  /> 
    })
}
   </datalist>
</Flex>
<Flex fontSize="1.2rem" flex=".5" top="8px">{item.paperid}</Flex>
<Flex fontSize="1.2rem" flex=".5" top="8px">{getpounds(item)}</Flex>
<Flex fontSize="1.2rem" top="8px">{po?.filter(filt => filt.stockid === item.paperid).map(it => {return it.ponum})}<br /></Flex>
<Flex fontSize="1.2rem" top="8px">{Math.ceil(parseInt(item.tottopress)) / parseInt(item.parentout)}</Flex>
<Flex fontSize="1.2rem" flex=".5"><Input type="text" onChange={e => changeimp(e,index,'parentout')} fontSize="1.1rem" value={item.parentout}  disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
<Flex flex="1.7">
       <Flexed>
       <Flex ><Input type="text" fontSize="1.1rem" defaultValue={item.parentsheetl} onBlur={e => setnewplength(e,item)} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
     <Flex ><Input type="text" fontSize="1.1rem" defaultValue={item.parentsheetw}  onBlur={e => setnewpwidth(e,item)} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>  
       </Flexed>
      </Flex>
<Flex fontSize="1.2rem" top="8px" >{po?.filter(filt => filt.stockid === item.paperid).map(it => {return it.vendor})}</Flex>


<Flex fontSize="1.3rem">{po?.filter(filt => filt.stockid === item.paperid).map(it => {return <Moment format="MM/DD/YY" style={{fontSize:"1.3rem"}}>{it.createdAt}</Moment>})}</Flex>
<Flex fontSize="1.3rem">{po?.filter(filt => filt.stockid === item.paperid).map(it => {return <Moment format="MM/DD/YY" style={{fontSize:"1.3rem"}}>{it.duedate}</Moment>})}</Flex>

{/*<Flex fontSize="1.2rem">{po?.map(purr => purr.items.filter(fil => parseInt(fil.stockid) === parseInt(item.paperid) && parseInt(fil.jobnum) === parseInt(param.job.replace(/\D/g, ""))).map(it => {return it.recievedstatus ? "Arrived" : "Awaiting"}))}</Flex>*/}
</Flexed>

})
}
<Flexed >
  <Flex>
<Textarea  value={customerinfo?.papernotes?.replace(/&amp;/g, "&")} placeholder="Pre-Press Notes"  onBlur={e => newinfo(e,'papernotes')} onChange={e => setCustomerinfo({...customerinfo,papernotes:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr")? false : true}/></Flex>

</Flexed>
</div>


{/*/////////////////////////////////////////////////////////BINDERY///////////////////////////////////////////////////////////////////////////////////////////*/}

<Flexed padding="7px 0px"  bgColor="#0E425A"  margin="20px 0px 0px 0px">
    <Flex color="white" flex="3" fontSize="1.3rem" fontWeight="bold">Bindery</Flex>  <Flex display="flex" justifyContent="flex-end"><Button hcolor="white" onClick={e => setBinderyticket(true)} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Bindery")? false : true}>Punches</Button></Flex>
</Flexed>


{imposition.map((item,index) => {
return <>
<Flexed bgColor="rgb(14,66,90,.4)" padding="3px" alignItems="center" >
  <Flex fontSize="calc(1rem + .2vw)" fontWeight="bold" flex="7.5">{item.component} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{item.numforms} Forms&nbsp; - &nbsp;{item.versions} version &nbsp;&nbsp; {item.pages}Pg / &nbsp;&nbsp; {item.numforms} - {item.pages / item.numforms} page(s) running {item.numup}up on a {item.length} X {item.width} {item.method}</Flex>
  {parseInt(item.numforms) > 1 &&   <>
       <Flex  fontSize="1.1rem">To Bindery: <Input type="text" fontSize="1.1rem" value={(parseInt(item.needed) + parseInt(item.additionalmr) + parseInt(item.mroverride) - parseInt(item.mroverride)) * parseInt(item.versions)} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex></>}
   </Flexed>
  {forms.filter(filt => filt.item == item.id).map((newitem,index) => {
return <>
   <Flexed bgColor="rgb(14,66,90,.2)" padding="3px" style={{marginBottom:"15px"}}>
  <Flex top="21px" fontSize="calc(1rem + .2vw)" fontWeight="bold" flex="1" >{newitem.name}</Flex>
  <Flex  fontSize="1.1rem" flex=".3">Pages:<br /> <Input type="text" fontSize="1.1rem" value={parseInt(newitem.pages)} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
    <Flex  fontSize="1.1rem" flex=".3">Forms:<br /> <Input type="text" fontSize="1.1rem" defaultValue={parseInt(newitem.numforms)} onBlur={e => changeformitem(e,item,index,'numforms')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
    <Flex  fontSize="1.1rem" flex=".3">Ver's:<br /> <Input type="text" fontSize="1.1rem" value={newitem.versions} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
    <Flex flex="1">
       Sheet Size:<br />
       <Flexed>
       <Flex><Input type="text" fontSize="1.1rem" value={newitem.length} onChange={e => changebinderyitem(e,item,index,'length')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
     <Flex ><Input type="text" fontSize="1.1rem" value={newitem.width}  onChange={e => changebinderyitem(e,item,index,'width')}disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
       </Flexed>
      </Flex>
      <Flex flex="1">
       Peice Size:<br />
       <Flexed>
       <Flex><Input type="text" fontSize="1.1rem" value={item.finishsizel} onChange={e => changebinderyitem(e,item,index,'finishsizel')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
     <Flex ><Input type="text" fontSize="1.1rem" value={item.finishsizew} onChange={e => changebinderyitem(e,item,index,'finishsizew')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
       </Flexed>
      </Flex>
      <Flex  fontSize="1.1rem" flex=".3">Cuts:<br /> <Input type="text" fontSize="1.1rem" value={item.cuts} onChange={e => changebinderyitem(e,item,index,'cuts')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
      <Flex  fontSize="1.1rem" >Fold Style:<br /> 
      <select value={item?.foldstyle} onChange={e => changebinderyitem(e,item,index,'foldstyle')}>
		<option value=""></option>
		<option value="Roll Fold">Roll Fold</option>
		<option value="Letter Fold">Letter Fold</option>
		<option value="Z Fold">Z Fold</option>
		<option value="Accordian Fold">Accordian Fold</option>
		<option value="Double Parallel">Double Parallel</option>
		<option value="Double Gate Fold">Double Gate Fold</option>
		<option value="1/4 Fold">1/4 Fold</option>
		<option value="Bi Fold">Bi Fold</option>
		<option value="4">4</option>
		<option value="6">6</option>
		<option value="8">8</option>
		<option value="12">12</option>
		<option value="16">16</option>
		<option value="20">20</option>
		<option value="24">24</option>
		<option value="32">32</option>
		<option value="M110">M110</option>
		<option value="Perf">Perf</option>
		<option value="Score" selected="">Score</option>
</select>
      
    </Flex>
      <Flex  fontSize="1.1rem" flex=".5">Die Cut:<br /> <Input type="text" fontSize="1.1rem" value={item.diecut} onChange={e => changebinderyitem(e,item,index,'diecut')} disabled={userInfo.departments?.some(cat => cat.label === "Administration")? false : true}/></Flex>
       <Flex fontSize="1.2rem" bgColor={colorchange(getstatus(newitem.partofid,'bindery',newitem.sett,newitem.digital,newitem.item))} 
         
       
          padding="5px" height="27px" top="15px">
      {getstatus(newitem.partofid,'bindery',newitem.sett,newitem.digital,newitem.item)}
          </Flex>
  </Flexed>
</>
}
  )}
</>
}
)}
<Flexed style={{marginTop:"-10px"}} border="1px solid rgb(0,0,0,.4)">
<Textarea value={customerinfo?.binderynotes?.replace(/&amp;/g, "&")} placeholder="Bindery Notes"  onBlur={e => newinfo(e,'binderynotes')} onChange={e => setCustomerinfo({...customerinfo,binderynotes:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr")? false : true}/>
</Flexed>



{/*/////////////////////////////////////////////////////////Stitching///////////////////////////////////////////////////////////////////////////////////////////*/}

<Flexed padding="7px 0px"  bgColor="#0E425A"  margin="20px 0px 0px 0px">
    <Flex color="white" flex="3" fontSize="1.3rem" fontWeight="bold">Binding /Banding</Flex>  <Flex display="flex" justifyContent="flex-end"><Button hcolor="white" onClick={e => setStitchingticket(true)} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Bindery")? false : true}>Punches</Button></Flex>
</Flexed>

<Flexed bgColor="#92A4AB" padding="4px 0px">
  <Flex fontSize="1.2rem" fontWeight="bold">Bind On</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex="2.5">Binding</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Drilling</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Coiling</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Band Style</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Band In</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Packing</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Handwork</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold"></Flex>
</Flexed>
<Flexed bgColor="rgb(14,66,90,.2)" padding="3px" style={{marginBottom:"5px"}} alignItems="center">
  <Flex fontSize="1.2rem">{customerinfo.bindon > 0 && customerinfo.bindon}</Flex>
  <Flex fontSize="1.2rem" flex="2.5">{customerinfo.stitchup > 0 && ` SaddleStitch ${customerinfo.stitchup} up / ${customerinfo.stitchpockets} pockets`}</Flex>
  <Flex fontSize="1.2rem">{customerinfo.drillholes > 0 ? <>{`Drill ${customerinfo.drillholes} holes - ${customerinfo.drillcover} Cover sheets & ${customerinfo.drilltext} Text sheets`}</> : ''}</Flex>
  <Flex fontSize="1.2rem"></Flex>
  <Flex fontSize="1.2rem">{customerinfo.bundletype > 0 && customerinfo.bundletype}</Flex>
  <Flex fontSize="1.2rem">{customerinfo.bundlein > 0 && customerinfo.bundlein}</Flex>
  <Flex fontSize="1.2rem">Carten Pack</Flex>
  <Flex fontSize="1.2rem">{parseInt(customerinfo.handwork) > 0 && "Yes"}</Flex>
  <Flex fontSize="1.2rem" bgColor={colorchange(getstichstatus('stitching'))} padding="5px" height="27px">
     {getstichstatus('stitching')}
         </Flex>
</Flexed>

<Flexed  style={{marginBottom:"15px"}} border="1px solid rgb(0,0,0,.4)">
<Textarea value={customerinfo?.bindingnotes?.replace(/&amp;/g, "&")} placeholder="Stitching Notes"  onBlur={e => newinfo(e,'bindingnotes')} onChange={e => setCustomerinfo({...customerinfo,bindingnotes:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr")? false : true}/>
</Flexed>

{/*/////////////////////////////////////////////////////////OSP///////////////////////////////////////////////////////////////////////////////////////////*/}

<Flexed padding="7px 0px"  bgColor="#0E425A"  margin="20px 0px 0px 0px">
    <Flex color="white" fontSize="1.3rem" fontWeight="bold">OSP</Flex> 
    <Flex textAlign="right" padding="0px 20px 0px 0px">
    <IoMdAddCircle onClick={() => setOspticket(true)} style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}}/>
   </Flex>
</Flexed>
<div style={{border:"1px solid rgb(0,0,0,.5)"}}>
<Flexed bgColor="#92A4AB" padding="4px 0px">
<Flex fontSize="1.2rem" fontWeight="bold" flex="4" >Description</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex=".7">Quantity</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex="2.5">Vendor</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Vendor Quote</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex=".5">PO #</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex=".5">Quote #</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex=".5">PDF</Flex>
  
  <Flex flex=".5"></Flex>
 
</Flexed>
{customerinfo.osp?.map((item,index) => {
return <Flexed padding="7px 0px" borderBottom="1px solid black">
    <Flex fontSize="1.3rem" flex="4" >{item.description}</Flex>
<Flex fontSize="1.3rem" flex=".7">
{item.quan1}
</Flex>
<Flex fontSize="1.3rem" flex="2.5">{item.vendor}</Flex>
<Flex fontSize="1.3rem" >{item.cusquote}</Flex>

<Flex fontSize="1.2rem" fontWeight="bold" flex=".5">{ospPo?.filter(filt => filt.vendor === item.vendor).map(it => {return it.ponum})}</Flex>
<Flex fontSize="1.3rem" flex=".5">{item.quote}</Flex>
<Flex fontSize="1.2rem" fontWeight="bold" flex=".5">

                   {item.pdf > "" && <FaFilePdf onClick={e => viewpopdf(item.pdf)} style={{fontSize:"calc(1.3rem + .2vw)"}}/>} 
              
                </Flex>
<Flex flex=".5"> <IoMdRemoveCircle onClick={e => deleteosp(index)} style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}}/></Flex>

</Flexed>

})
}
<Flexed >
  <Flex>
<Textarea  value={customerinfo?.ospnotes?.replace(/&amp;/g, "&")} placeholder="OSP Notes"  onBlur={e => newinfo(e,'ospnotes')} onChange={e => setCustomerinfo({...customerinfo,ospnotes:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr")? false : true}/></Flex>

</Flexed>
</div>



{/*/////////////////////////////////////////////////////////Mailing///////////////////////////////////////////////////////////////////////////////////////////*/}

<Flexed padding="7px 0px"  bgColor="#0E425A"  margin="20px 0px 0px 0px">
    <Flex color="white" flex="3" fontSize="1.3rem" fontWeight="bold">Mailing</Flex>  <Flex display="flex" justifyContent="flex-end"><Button hcolor="white" onClick={e => setMailticket(true)} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Mailroom")? false : true}>Punches</Button></Flex>
</Flexed>

<Flexed bgColor="#92A4AB" padding="4px 0px">
  <Flex fontSize="1.2rem" fontWeight="bold">Processing</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold" flex="2.5">Tabbing</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Inkjet</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Inserting</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Mail Match</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold">Political</Flex>
  <Flex fontSize="1.2rem" fontWeight="bold"></Flex>
</Flexed>
<Flexed bgColor="rgb(14,66,90,.2)" padding="3px" style={{marginBottom:"5px"}} alignItems="center">
  <Flex fontSize="1.2rem"></Flex>
  <Flex fontSize="1.2rem" flex="2.5"></Flex>
  <Flex fontSize="1.2rem"></Flex>
  <Flex fontSize="1.2rem"></Flex>
  <Flex fontSize="1.2rem"></Flex>
  <Flex fontSize="1.2rem"></Flex>
  <Flex fontSize="1.2rem" bgColor={colorchange(getstichstatus('mailing'))} padding="5px" height="27px">
     {getstichstatus('mailing')}
         </Flex>
</Flexed>

<Flexed  style={{marginBottom:"15px"}} border="1px solid rgb(0,0,0,.4)">
<Textarea value={customerinfo?.mailingnotes?.replace(/&amp;/g, "&")} placeholder="Mailing Notes"  onBlur={e => newinfo(e,'mailingnotes')} onChange={e => setCustomerinfo({...customerinfo,mailingnotes:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr")? false : true}/>
</Flexed>

{/*/////////////////////////////////////////////////////////Shipping///////////////////////////////////////////////////////////////////////////////////////////*/}

<Flexed padding="7px 0px"  bgColor="#0E425A"  margin="20px 0px 0px 0px">
    <Flex color="white" fontSize="1.3rem" fontWeight="bold">Shipping</Flex> 
  
</Flexed>
<div style={{border:"1px solid rgb(0,0,0,.5)"}}>
{
customerinfo?.shippingitems?.map((item,index) => {
  console.log(item)
  return<div  style={{border:"1px solid rgb(0,0,0,.3)",marginBottom:"10px"}}>
  <Flexed padding="5px" bgColor="rgb(0,0,0,.3)">
  <Flex fontSize="calc(1.1rem + .2vw)">{item.locationname} - {item.address1 ? item.address1 : ""} {item.address2 ? item.address2 : ""} {item.city ? item.city : ""} {item.state ? item.state : ""} {item.zip ? item.zip : ""}</Flex>
</Flexed>
<Flexed style={{borderBottom:"1px solid black"}}>
  <Flex fontSize="calc(1.2rem + .2vw)">Item</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">Ordered</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Ship Date </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Ship Method</Flex>
    <Flex flex=".1" fontSize="calc(1.2rem + .2vw)"></Flex>
</Flexed>
{
item?.items?.map(ite => {
 return <Flexed className="normalrow">
  <Flex fontSize="calc(1.2rem + .2vw)">{ite.name}</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">{ite.requested}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Moment style={{fontSize:"calc(1.2rem + .2vw)"}} format="MM/DD/YYYY">{new Date(ite.shipdate)}</Moment></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">{ite.shipmethod}</Flex>
    <Flex flex=".1" fontSize="calc(1.2rem + .2vw)"></Flex>
</Flexed>
})
}

{
  sentitems.filter(filt => filt.shiptoid === item.locationid).map(it => {
   return  <Collapse title={<Flexed  fontWeight="bold" padding="3px 0px" fontSize="calc(1rem + .2vw)" bgColor="#9BB0C1" margin="3px 0px" className="hand">
    <Flex>{it.shipid}</Flex>
    <Flex></Flex>
  
    <Flex flex=".1"></Flex>
  </Flexed>}>
  <Flexed color="#0E425A" borderBottom="1px solid grey" padding="6px 0px">
     <Flex >Item</Flex>
     <Flex flex=".4">Sent</Flex>
     <Flex>Description</Flex>
    
     <Flex flex=".4">Ship Via</Flex>
     <Flex flex=".4">Ship Date</Flex>
   </Flexed>
    {
    it.items.map(itemm => {
     return <>  
     
     <Flexed color="#0E425A" padding="6px 0px">
    <Flex>{itemm.name}</Flex>
    <Flex flex=".4">{itemm.sending}</Flex>
    <Flex><Textarea style={{border:"none",background:"#EAE6E1"}} disabled>{itemm.description}</Textarea></Flex>
    <Flex flex=".4">{it.method}</Flex>
    <Flex flex=".4"><Moment format={"MM/DD/YYYY"} >{it.dateshipped}</Moment></Flex>
  </Flexed>
  </>
    })
  }
    </Collapse>
  })
}
</div> 
})

}
 

<Flexed >
  <Flex>
<Textarea  value={customerinfo?.shippingnotes?.replace(/&amp;/g, "&")} placeholder="Shipping Notes"  onBlur={e => newinfo(e,'shippingnotes')} onChange={e => setCustomerinfo({...customerinfo,shippingnotes:e.target.value})} disabled={userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Csr")? false : true}/></Flex>

</Flexed>
</div>
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>
        </JobticketProvider>
    </MainWrap>
  )
}

export default JobTicket